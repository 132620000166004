import "../assets/styles/quizResultPage.css";
import { useEffect, useState } from "react";
import QuizApi from "../services/QuizApi";
import { MainNavbar } from "./Common/Navbar";
import { format, differenceInSeconds } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PageSelect, CustomPagination } from "./ManageQuiz";
import {
  FaAnglesRight,
  FaAnglesLeft,
  FaMagnifyingGlass,
} from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function QuizResultPage() {
  const [quizData, setQuizData] = useState([]);
  const [filteredQuizData, setFilteredQuizData] = useState([]);
  const order = sessionStorage.getItem("order") || "-quizstarttime";
  if (!sessionStorage.getItem("order")) {
    sessionStorage.setItem("order", order);
  }
  const [sortOrder, setSortOrder] = useState({ key: order.replace('-',''), ascending: order.startsWith('-') });
  let { page } = useParams();
  const { quizId } = useParams();
  const localStorageKey = "userInteger";
  const entriesPerPage = parseInt(
    localStorage.getItem(localStorageKey) || "20"
  );
  const currentPage = Number(page) || 0;
  const offset = currentPage * entriesPerPage;
  const navigate = useNavigate();
  let sessionSearch = "";
  if (sessionStorage.getItem("search")) {
    sessionSearch = sessionStorage.getItem("search");
  }
  const [searchTerm, setSearchTerm] = useState(sessionSearch);
  const [searchTermNotFound, setSearchTermNotFound] = useState(sessionSearch);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReportDelete, setIsReportDelete] = useState(false);
  const QuestionStatusTooltip = { 'Security Stopped': ['Indicates that the quiz submission was triggered by a Security breach.', '#904800'], 'Timed-Out': ['The user did not finish the quiz within the allocated time, leading to an automatic', '#808080'], 'Completed': ['The user has answered all questions and submitted the quiz, marking it as fully completed.','#008000'], 'In Progress': ['The quiz is currently being taken by the user and is not yet submitted.', '#4C33FF'], 'Incomplete': ['When the user has left the quiz before submitting all answers or before reaching the end of the quiz.', '#FF0000'] }


  const loadQuizData = async () => {
    try {
      let order = sortOrder.key
      if (sortOrder.ascending && sortOrder.key) {
        order = `-${order}`;
      }
      const response = await QuizApi.get(

        `/quiz-result/${quizId}/get_quiz_results_by_quiz_id?order=${order}`
      );
      if (searchParams.get("search")) {
        setSearchTerm(searchParams.get("search"));
        const terms = searchParams.get("search").split("+");
        let filteredData = applySearchFilters(response.data, terms[0]);
        for (let i = 1; i < terms.length; i++) {
          filteredData = applySearchFilters(filteredData, terms[i]);
        }
        setFilteredQuizData(filteredData);
      }else{
        setQuizData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadQuizData();
    sessionStorage.setItem('page_number', page||0);
  }, [page]);

  useEffect(() => {
    setFilteredQuizData(quizData);
  }, [quizData]);

  const handlePageChange = (selected) => {
    if (selected >= 0 && selected < totalPageCount) {
      navigate(`/quiz-result/${quizId}/${selected}`);
      window.scrollTo(0, 0);
    }
  };

  const sortQuizData = () => {
    const sortedData = [...filteredQuizData];
    sortedData.sort((a, b) => {
      const aValue = a[sortOrder.key];
      const bValue = b[sortOrder.key];

      if (sortOrder.ascending) {
        if (typeof aValue === "number" && typeof bValue === "number") {
          return aValue - bValue;
        } else if (aValue instanceof Date && bValue instanceof Date) {
          return aValue - bValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
      } else {
        if (typeof aValue === "number" && typeof bValue === "number") {
          return bValue - aValue;
        } else if (aValue instanceof Date && bValue instanceof Date) {
          return bValue - aValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return bValue.localeCompare(aValue);
        }
      }
      return 0;
    });

    setFilteredQuizData(sortedData);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredQuizData(quizData);
  };

  const handleSort = (key) => {
    let order = "asc";
    if (sortOrder.key === key) {
      if (sortOrder.ascending) {
        order = key;
      } else {
        order = `-${key}`;
      }
      setSortOrder({ key, ascending: !sortOrder.ascending });
    } else {
      order = `-${key}`;
      setSortOrder({ key, ascending: true });
    }

    QuizApi.get(
      `/quiz-result/${quizId}/get_quiz_results_by_quiz_id?order=${order}`
    ).then(response => {
      setQuizData(response.data);
    }).catch(error => { console.log(error) });


  };

  const renderSortingIcon = (columnKey) => {
    if (sortOrder.key === columnKey) {
      return sortOrder.ascending ? (
        <FontAwesomeIcon icon={faSortDown} />
      ) : (
        <FontAwesomeIcon icon={faSortUp} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [isDeleteDropdownVisible, setDeleteDropdownVisible] = useState(false);

  const areAllCheckboxesSelected = () => {
    return Object.values(selectedCheckboxes).every(Boolean);
  };

  const isAnyCheckboxSelected = () => {
    return Object.values(selectedCheckboxes).some(Boolean);
  };

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;

    setSelectedCheckboxes((prevSelectedCheckboxes) => ({
      ...prevSelectedCheckboxes,
      [id]: checked,
    }));

    setSelectAllChecked(areAllCheckboxesSelected());
  };

  const handleSelectAll = () => {
    const updatedCheckboxes = {};
    if (!page) {
      page = 0;
    }


    let selected_value = document.getElementById('integerSelect');
    if (selected_value) {
      selected_value = parseInt(selected_value.value);
    } else {
      selected_value = 20;
    }
    for (const report of filteredQuizData.slice(selected_value * page, selected_value * (parseInt(page) + 1))) {
      updatedCheckboxes[report.id] = !selectAllChecked;
    }

    setSelectedCheckboxes(updatedCheckboxes);
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    setSelectAllChecked(false);
  }, [page]);

  useEffect(() => {
    const initialSelectedCheckboxes = {};

    for (const report of filteredQuizData) {
      initialSelectedCheckboxes[report.id] = false;
    }

    setSelectedCheckboxes(initialSelectedCheckboxes);
  }, [filteredQuizData]);

  function calculateTimeDifference(startTime, endTime) {
    const startTimeObj = new Date(startTime);
    const endTimeObj = new Date(endTime);

    const timeDifference = differenceInSeconds(endTimeObj, startTimeObj);
    const hours = Math.floor(timeDifference / 3600);
    const minutes = Math.floor((timeDifference % 3600) / 60);
    const seconds = timeDifference % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  const showDeleteDropdown = () => {
    if (areAllCheckboxesSelected()) {
      setDeleteDropdownVisible(true);
    }
  };

  const hideDeleteDropdown = () => {
    setDeleteDropdownVisible(false);
  };

  const confirmDelete = async () => {
    hideDeleteDropdown();
    const selectedIds = Object.keys(selectedCheckboxes).filter(
      (id) => selectedCheckboxes[id]
    );

    try {
        await QuizApi.delete(`/quiz-result/${selectedIds[0]}/`,{data: {reportIds: selectedIds}});

      setSelectedCheckboxes({});
      setSelectAllChecked(false);
      loadQuizData();
      let selected_value = document.getElementById('integerSelect');
      if (!selected_value) {
        window.location.reload();
      }
      if(parseInt(page) === parseInt(totalPageCount-1)){
        navigate(`/quiz-result/${quizId}/${page-1}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteConfirmationModal = (
    <div className="delete-confirmation-modal">
      <div className="delete-confirmation-content">
        <p
          style={{
            textAlign: "left",
            marginBottom: "5px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Are you sure you want to delete these reports?
        </p>
        <p style={{ textAlign: "left" }}>
          All reports will be removed permanently and will not be recoverable.
          You may download all reports now before deleting.
        </p>
        <div className="deletepopup_btn">
          <button onClick={confirmDelete}>Delete</button>
          <button onClick={hideDeleteDropdown}>Cancel</button>
        </div>
      </div>
    </div>
  );

  const totalPageCount = Math.ceil(filteredQuizData.length / entriesPerPage);

  const applySearchFilters = (data, term) => {
    return data.filter((item) => {
      const searchableFields = [
        item.name,
        item.email,
        item.phonenumber,
        item.score.toString(),
        item.securityalerts.toString(),
        item.result,
        format(new Date(item.quizstarttime), "dd MMM yyyy hh:mm aa"),
        format(new Date(item.quizendtime), "dd MMM yyyy hh:mm aa"),
      ];

      for (const field of searchableFields) {
        if (field.toLowerCase().includes(term.toLowerCase())) {
          return true;
        }
      }

      return false;
    });
  };

  const handleSearch = () => {
    sessionStorage.setItem("search", searchTerm);
    setSearchTermNotFound(searchTerm);
    if (searchTerm.trim() === "") {
      setFilteredQuizData(quizData);
    } else {
      let order = sortOrder.key
      if (sortOrder.ascending && sortOrder.key) {
        order = `-${order}`;
      } QuizApi.get(`/quiz-result/${quizId}/get_quiz_results_by_quiz_id?order=${order}&search=${searchTerm.toLowerCase().replace(/\s+/g,' ').trim()}`).then(response => {
        setFilteredQuizData(response.data);
        navigate(`/quiz-result/${quizId}/0`);
        let timeoutId = setTimeout(()=>{
          const is_search = sessionStorage.getItem("is_search");
          if(is_search === "true"){
              clearTimeout(timeoutId);
              handleSearch();
              sessionStorage.setItem("is_search",false);
              return 0;
          }
        },5);
      }
      ).catch(error => { console.log(error) });
    }
  };

  useEffect(() => {
    let sessionSearch = sessionStorage.getItem("search");
    if (sessionSearch) {
      handleSearch();
    }
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "" && page === "0") {
      navigate(`/quiz-result/${quizId}/${0}`);
      handleSearch();
    }
  }, [searchTerm]);


  const reportDelete = () => {
    let order = sessionStorage.getItem("order");
    QuizApi.get(
      `/quiz-result/${quizId}/get_quiz_results_by_quiz_id?order=${order}`
    ).then(response => {
      setQuizData(response.data);
    }).catch(error => { console.log(error) });
  }

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === "reportDelete") {
        reportDelete();
      }
    })
  }, []);

  useEffect(() => {
    let order = sortOrder.key
    if (sortOrder.ascending && sortOrder.key) {
      order = `-${order}`;
    }
    sessionStorage.setItem("order", order);
  }, [sortOrder]);


  return (
    <>
      <MainNavbar />

      <div className="container_main restorescroll reportsPageContainer">
        <div className="quiz-title-head" style={{paddingLeft:"0px"}}>
          {quizData[0]?.quiz_title}
        </div>
        <div className="formclass">
          <div className="searchcontainer">
            <div style={{ display: "flex", alignItems: "flex-end", }}>
              {
                parseInt(window.history.state.idx) !==0 && (
                  <button className="btnquiz btnResultQuiz" style={{ fontSize: 18, minWidth: "130px", paddingBlock: "0.35rem" }} onClick={()=>{(window.history.back())}}>
                    Back
                  </button>
                )
              }
              <button
                type="button"
                className={`btnquiz btnResultQuiz ${!isAnyCheckboxSelected()  && "disable"}  ${isDeleteDropdownVisible ? "active" : ""
                  }`}
                style={{ fontSize: 18, minWidth: "130px", paddingBlock: "0.35rem" }}
                onClick={() => {
                  if (areAllCheckboxesSelected()) {
                    showDeleteDropdown();
                  } else {
                    const selectedIds = Object.keys(selectedCheckboxes).filter(
                      (id) => selectedCheckboxes[id]
                    );

                    if (selectedIds.length > 0) {
                      confirmDelete();
                    }
                  }
                }}
              >
                Delete
              </button>
              <button
                className={`btnquiz btnResultQuiz ${!isAnyCheckboxSelected()  && "disable"}`}
                style={{ fontSize: 18, minWidth: "130px", paddingBlock: "0.35rem" }}
              >
                Download
              </button>
            </div>
            <div className="main_heading " style={{ marginTop: "0px" }}>
              Quiz Results
            </div>
            <div className="search_bar">
              <button
                title="Search"
                type="button"
                className="button_search"
                onClick={()=>{handleSearch(); sessionStorage.setItem("is_search",true);}}
              >
                <FaMagnifyingGlass />
              </button>
              <input
                type="text"
                placeholder="Search by name, email, phone, or keywords in essay answers."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => { (e.key === "Enter") && handleSearch(); (e.key === "Enter") && sessionStorage.setItem("is_search",true); }}
              />
              {searchTerm && (
                <button
                  title="Clear Search"
                  type="button"
                  className="button_clear"
                  onClick={handleClearSearch}
                >
                  {/* <FaTimes /> */}
                  <FontAwesomeIcon icon={faXmark} size="xl" />

                </button>
              )}
            </div>
          </div>
          {isDeleteDropdownVisible && deleteConfirmationModal}
          <div className="quizreporttablecontainer">
            <table className="quizreporttable quizresulttable">
              <tbody>
                <tr className="header_Container">
                  <th className="sort-by reducedwidthDateAndTime checkbox_container">
                    <a
                      style={{
                        fontSize: 28,
                        display: "flex",
                        alignItems: "center",
                        marginRight: "0px",
                      }}
                      onClick={handleSelectAll}
                    >
                      <input
                        style={{ height: 22 }}
                        type="checkbox"
                        checked={selectAllChecked}
                        onChange={handleSelectAll}
                      />
                    </a>
                    <span onClick={() => handleSort("quizstarttime")}>
                      Date and Time {renderSortingIcon("quizstarttime")}
                    </span>
                  </th>
                  <th className="sort-by" onClick={() => handleSort("name")}>
                    <span>Name {renderSortingIcon("name")}</span>
                  </th>
                  <th className="sort-by" onClick={() => handleSort("email")}>
                    <span>Email {renderSortingIcon("email")}</span>
                  </th>
                  <th
                    className="sort-by reducedwidthPhoneNumber"
                    onClick={() => handleSort("phonenumber")}
                  >
                    <span>Phone Number {renderSortingIcon("phonenumber")}</span>
                  </th>
                  <th
                    className="sort-by reducedWidthTh"
                    onClick={() => handleSort("percentage")}
                  >
                    <span>Score {renderSortingIcon("percentage")}</span>
                  </th>
                  <th
                    className="sort-by reducedWidthTimeTakenTableHeader"
                    onClick={() => handleSort("quizendtime")}
                  >
                    <span>Time Taken {renderSortingIcon("quizendtime")}</span>
                  </th>
                  <th
                    className="sort-by reducedWidthTh"
                    onClick={() => handleSort("securityalerts")}
                  >
                    <span>Alerts {renderSortingIcon("securityalerts")}</span>
                  </th>
                  <th
                    className="sort-by reducedWidthTh"
                    onClick={() => handleSort("status")}
                  >
                    <span>Status {renderSortingIcon("status")}</span>
                  </th>
                  <th
                    className="sort-by reducedWidthTh"
                    onClick={() => handleSort("result")}
                  >
                    <span>Result {renderSortingIcon("result")}</span>
                  </th>
                  <th className="sort-by reducedWidthTh">
                    <span>Report </span>
                  </th>
                </tr>
                {filteredQuizData
                  .slice(offset, offset + entriesPerPage)
                  .map((ReportData, i) => (
                    <tr key={i}>
                      <td className="checkbox_container">
                        <input
                          type="checkbox"
                          id={ReportData.id}
                          className="quizResultCheckboxes"
                          name="reportid"
                          checked={selectedCheckboxes[ReportData.id] || false}
                          onChange={(event) =>
                            handleCheckboxChange(event, ReportData.id)
                          }
                        />{" "}
                        <span style={{ verticalAlign: "-2px" }}>
                          {format(
                            new Date(ReportData.quizstarttime),
                            "dd MMM yyyy hh:mm aa"
                          )}{" "}
                        </span>
                      </td>
                      <td>{ReportData.name || "-"}</td>
                      <td>{ReportData.email || "-"}</td>
                      <td>{ReportData.phonenumber || "-"}</td>
                      <td>{ReportData.percentage}%</td>
                      <td>
                        {calculateTimeDifference(
                          ReportData.quizstarttime,
                          ReportData.quizendtime
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {ReportData.securityalerts}
                      </td>
                      <td title={QuestionStatusTooltip[ReportData?.status][0]}>
                        
                        <font color={`${QuestionStatusTooltip[ReportData?.status][1]}`}>
                          {ReportData?.status}
                        </font>
                      </td>
                      <td>
                        {ReportData.result ? (
                          <font style={{textTransform: 'capitalize'}} color={`${ReportData?.result === "pass" ? "green" : ReportData?.result === "non graded" ? "black" : "red"}`}>{ReportData?.result?.charAt(0).toUpperCase() + ReportData?.result?.substr(1)}</font>
                        ) : (
                          <font color="red">-</font>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/quiz-report/${quizId}/${ReportData.id}/?sort=${sessionStorage.getItem("order")}`}
                          onClick={() => {sessionStorage.setItem("quizId", quizId);}}
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        {searchTerm && filteredQuizData.length === 0 ? (
          <div className="no-results-message">
            No results found for "{searchTermNotFound}".
            <button className="btn btn-danger" onClick={handleClearSearch}>
              Clear Search
            </button>
          </div>
        ) : !searchTerm && filteredQuizData.length === 0 ? (
          <div className="no-results-message">No reports Found.</div>
        ) : null}
        {filteredQuizData.length >= 18 && (
          <div className="pagination" style={{ marginTop: "30px" }}>
            <div style={{ width: "100%", textAlign: "left" }}>
              <PageSelect />
            </div>
            <button
              className="previouspage pagelink"
              onClick={() => navigate(`/quiz-result/${quizId}/${0}`)}
            >
              <FaAnglesLeft style={{ fontSize: "25px" }} />
            </button>
            <CustomPagination
              currentPage={currentPage}
              pageCount={totalPageCount}
              onPageChange={handlePageChange}
            />
            <button
              className="nextpage pagelink"
              onClick={() =>
                navigate(`/quiz-result/${quizId}/${totalPageCount - 1}`)
              }
            >
              <FaAnglesRight style={{ fontSize: "25px" }} />
            </button>
          </div>
        )}
        <br />
      </div>
    </>
  );
}
