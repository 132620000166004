import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import QuizApi from "../../services/QuizApi";

export default function Sidebar(props) {
  let navigator = useNavigate();
  const [question, setQuestion] = useState([]);
  const location = useLocation();

  useEffect(() => {
    async function loadQuestions() {
      try {
        let questions = await QuizApi.get("questions/");
        setQuestion(questions.data);
      } catch (error) {
        console.log("Error:", error);
        navigator("/");
      }
    }
    loadQuestions();
  }, []);

  const isEditQuizPage = location.pathname.includes("/editquiz");
  const isGeneralSettingsPage = location.pathname.includes("general-settings");
  const isBrowserSettingsPage = location.pathname.includes("security-settings");
  const isScoringOption = location.pathname.includes("scoring-option");
  const isQuizTracking = location.pathname.includes("quiz-tracking");
  const isSmartProctoring = location.pathname.includes(
    "smart-proctoring-system"
  );
  const isLeadIntegration = location.pathname.includes("lead-integration");

  useEffect(() => {
    const topHeader = document.getElementById("topheader");
    if (topHeader) {
      if (
        isGeneralSettingsPage ||
        isBrowserSettingsPage ||
        isScoringOption ||
        isQuizTracking ||
        isSmartProctoring ||
        isLeadIntegration
      ) {
        topHeader.classList.add("nav_fixed");
      } else {
        topHeader.classList.remove("nav_fixed");
      }
    }
  }, [
    isGeneralSettingsPage,
    isBrowserSettingsPage,
    isScoringOption,
    isQuizTracking,
    isSmartProctoring,
    isLeadIntegration,
  ]);

  return (
    <div className="sidebar-main" id="settings_sidebar">
      <ul className="sidebar-links">
        {props.links.map((link, index) => (
          <li
            key={index}
            className={
              isGeneralSettingsPage && index === 0
                ? "highlighted-link"
                : "" || (isBrowserSettingsPage && index === 3)
                ? "highlighted-link"
                : "" || (isScoringOption && index === 1)
                ? "highlighted-link"
                : "" || (isQuizTracking && index === 2)
                ? "highlighted-link"
                : "" || (isSmartProctoring && index === 4)
                ? "highlighted-link"
                : "" || (isLeadIntegration && index === 5)
                ? "highlighted-link"
                : ""
            }
          >
            <Link to={link.to}>{link.text}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
